/* ------------------------------------ *\
		tools.action
\* ------------------------------------ */

@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

@mixin focusBoxShadow($inset, $include-transition) {
	outline-color: var(--color-focus-outline);
	outline-offset: 0.8rem;
	outline-width: 0.3rem;
	outline-style: solid;

	@if $include-transition {
		@include transition {
			transition-property: outline-color, outline-offset;
			transition-duration: var(--trans-time--standard);
			transition-timing-function: ease-in-out;
		}
	}
}

@mixin actionBase($focus-transition: true) {
	cursor: pointer;
	font-family: var(--font-secondary);

	&:focus-visible {
		@include focusBoxShadow("", $focus-transition);
	}
}

@mixin action {
	@include actionBase;
	color: var(--color-action--1);
	line-height: var(--line-height--2xl);
	text-decoration: underline;
	@include transition {
		transition: all var(--trans-time--standard) var(--ease--dynamic);
	}

	@include HoverFocus {
		text-decoration: none;
	}
}

@mixin buttonBase {
	display: inline-block;
	border-radius: var(--corner-size--xl);
	border: var(--border--md) solid transparent;
	background: transparent;
	text-align: center;
	text-decoration: none;
	font-size: var(--font-size--md);

	@include HoverFocus {
		color: var(--color-white);
		background-color: var(--color-dark--1);
		border-color: var(--color-dark--1);
		text-decoration: none;
	}

	@include transition {
		transition: all var(--trans-time--shorter);
	}
}

@mixin button {
	@include actionBase;

	&___primary {
		@include buttonBase;
		color: var(--color-white);
		background: var(--color-action--1);

		&-negative {
			@include buttonBase;
			background: var(--color-white);
			color: var(--color-action--1);
		}
	}

	&___secondary {
		@include buttonBase;
		color: var(--color-action--1);
		border-color: var(--color-action--1);

		&-negative {
			@include buttonBase;
			color: var(--color-white);
			border-color: var(--color-white);
		}
	}

	&___md {
		padding: var(--spacing--xs) var(--spacing--sm);
		font-weight: var(--font-weight--medium);
		line-height: var(--line-height--lg);
	}

	&___sm {
		padding: var(--spacing--3xs) var(--spacing--xs);
		line-height: var(--line-height--2xl);
	}

	&___link {
		@include action;
		border: 0;
		padding: 0;
		background: transparent;
		font-weight: var(--font-weight--normal);
	}
}

@mixin buttonDisabled {
	opacity: 0.5;
	cursor: not-allowed;
}

// Input
@mixin input {
	font-family: var(--font-secondary);
	line-height: var(--line-height--md);
	outline: none;
	padding: var(--spacing--2xs) var(--spacing--xs);
	font-size: var(--forminput-font-size--md);
	min-height: var(--sizes-input-height);
	background: transparent;
	border: var(--border--sm) solid var(--color-darkGrey);
	border-radius: 1rem;
	color: var(--color-darkGrey);

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&___borderlessWhite {
		border-color: transparent;
		background-color: var(--color-white);
	}

	&.hasError {
		border-color: var(--color-error);
		color: var(--color-error);
	}

	&::placeholder {
		color: var(--color-mediumGrey);
	}

	&:focus-visible {
		@include focusBoxShadow("", true);
	}

	// Used for text, email, password, number etc.
	&___string {
		width: 100%;
		border: var(--border--sm) solid transparent;
		background: var(--color-gradient--5);
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}

	&___string:focus,
	&___active {
		border-color: currentColor;
	}

	// Used for checkbox and radio
	&___choice {
		height: 1.5em;
		width: 1.5em;
	}

	// Used for textarea
	&___textarea {
		width: 100%;
		border: var(--border--sm) solid currentColor;
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}
}
