/* ------------------------------------ *\
		tools.typography-mixins
\* ------------------------------------ */
@import "ui/styles/2-tools/tools.display-mixins.scss";

@mixin heading--2xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--5xl);
	line-height: var(--line-height--sm);
	hyphens: auto;
}

@mixin heading--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--4xl);
	line-height: var(--line-height--md);
	hyphens: auto;
}

@mixin heading--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--3xl);
	line-height: var(--line-height--md);
	hyphens: auto;
}

@mixin heading--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--2xl);
	line-height: var(--line-height--md);
	hyphens: auto;
}

@mixin heading--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--lg);
	hyphens: auto;
}

@mixin heading--xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--lg);
	hyphens: auto;
}

@mixin heading--2xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--heading-font-size--2xs);
	line-height: var(--line-height--xl);
	hyphens: auto;
}

@mixin heading--3xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--heading-font-size--3xs);
	line-height: var(--line-height--xl);
	hyphens: auto;
}

@mixin lead {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--lead-font-size--md);
	line-height: var(--line-height--2xl);
}

@mixin lead--lg {
	font-size: var(--lead-font-size--lg);
}

@mixin paragraph {
	font-family: var(--font-secondary);
	font-weight: var(--font-weight--normal);
	line-height: var(--line-height--2xl);
}

@mixin paragraph--lg {
	font-size: calc(var(--font-size--md) + 0.2rem);
}

@mixin paragraph--strong {
	font-weight: var(--font-weight--semibold);
}

@mixin text-link--md {
	font-family: var(--font-secondary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
}

@mixin text-link--sm {
	font-family: var(--font-secondary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--xl);
}

@mixin quote {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--quote-font-size--lg);
	line-height: var(--line-height--xl);
	font-style: italic;

	&___secondary {
		font-family: var(--font-secondary);
		font-weight: var(--font-weight--medium);
		font-size: var(--quote-font-size--md);
		line-height: var(--line-height--xl);
		font-style: normal;
	}
}

@mixin caption {
	font-family: var(--font-secondary);
	margin: 0;

	&___primary {
		font-size: var(--font-size--sm);
		line-height: var(--line-height--xl);
		font-weight: var(--font-weight--normal);
	}

	&___secondary {
		font-size: var(--font-size--xs);
		line-height: var(--line-height--3xl);
		font-weight: var(--font-weight--semibold);
		text-transform: uppercase;
		letter-spacing: 0.12em;
	}
}

@mixin subheading {
	@include caption;

	margin: 0;
}

@mixin formfield-label {
	font-family: var(--font-secondary);
	font-size: var(--formlabel-font-size--md);
	font-weight: var(--font-weight--semibold);
	color: currentColor;
	cursor: pointer;
	display: flex;
	width: 100%;

	&:hover {
		color: var(--color-action--1);
	}

	&.isDisabled {
		color: var(--color-darkGrey);
		cursor: auto;
	}

	&.isHidden {
		@include visually-hidden;
	}

	&.hasError {
		color: var(--color-error);
	}
}

@mixin ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin lineclamp($lines) {
	@if $lines {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: $lines;
		line-clamp: $lines;
		-webkit-box-orient: vertical;
	} @else {
		@error "Property #{$lines} does not exist";
	}
}
